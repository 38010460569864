import React from 'react';
import Char from '../helpers/chars';

function GameWord(props) {
  return (
    <ul className="game-word">
      {props.word.split('').map((char, index) => {
      return <li className="word-letter" key={`word-letter-nr-${index}`}>
              <span>
                {props.choosenChars.indexOf(char) < 0 ? ' ' : Char()[char]}
              </span>
            </li>
      })}
    </ul>
  );
}

export default GameWord;
