import React from 'react';
import Words from './helpers/words';
import Chars from './helpers/chars';
import CharBoard from './components/charBoard';
import ChoosenChars from './components/choosenChars';
import GameWord from './components/word';
import Man, {paths} from './components/man';
import './App.css';

class App extends React.Component {
  constructor() {
    super();
    var randomIndex = Math.floor(Math.random() * (Words.length - 1));
    this.state = {
      currentWord: Words[randomIndex],
      availableChars: Object.keys(Chars()).map(char => char),
      choosenChars: [],
      totalTrys: paths.length
    };
  }
  newGame() {
    var randomIndex = Math.floor(Math.random() * (Words.length - 1));
    this.setState({
      currentWord: Words[randomIndex],
      availableChars: Object.keys(Chars()).map(char => char),
      choosenChars: [],
    });
  }
  takeChar(char) {
      this.state.choosenChars.push(char);
      var charsLeft = this.state.availableChars;
      charsLeft.splice(this.state.availableChars.indexOf(char), 1)
      this.setState({
        availableChars: charsLeft
      });
  }
  getNumberOfWrongAnswers() {
      var numberOfWrongAnswers = 0;
      this.state.choosenChars.forEach((char) => {
          if(this.state.currentWord.indexOf(char) < 0) {
              numberOfWrongAnswers++;
          }
      });
      return numberOfWrongAnswers;
  }
  isDone() {
    var rightLetters = [];
    this.state.currentWord.split('').forEach((char) => {
      if(this.state.choosenChars.indexOf(char) >= 0) {
        rightLetters.push(char);
      }
    });
    return this.state.currentWord.length === rightLetters.length;
  }
  isDead() {
    return this.getNumberOfWrongAnswers() >= this.state.totalTrys;
  }
  render() {
    return (
      <div className="App">
        <GameWord word={this.state.currentWord} choosenChars={this.state.choosenChars} />
        <div className="man">
          <Man numberOfWrongAnswers={this.getNumberOfWrongAnswers()} />
        </div>
        <div className="char-pad">
          {(!this.isDead() && !this.isDone()) ? 
            <>
              <ChoosenChars choosenChars={this.state.choosenChars} />
              <CharBoard availableChars={this.state.availableChars} takeChar={this.takeChar.bind(this)} />
            </>
          : null}
          {(this.isDead() || this.isDone()) ?   
          <>
            <div class="overlay"></div>
            <div class="modal">
            {this.isDead() ? 
              <h1>SLUT :(</h1>
            : null}
            {this.isDone() ? 
              <h1>GRATTIS :)</h1>
            : null}
              <button onClick={() => {
                this.newGame();
              }}>SPELA IGEN</button>
            </div>
          </>
          : null}
        </div>
      </div>

    );
  }
}

export default App;
