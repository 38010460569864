import React from 'react';

function ChoosenChars(props) {
  return (
    <>
    { props.choosenChars.length > 0 ? 
      <div className="choosen-chars">
        <ul className="char-list">
          {props.choosenChars.map(char => {
          return <li key={`choosen-${char}`}>
                    {char.toUpperCase()}
                </li>
          })}
        </ul>
      </div>
      : null}
    </>
  );
}

export default ChoosenChars;
