let Words = [
    'ägg',
    'xylofon',
    'flaska',
    'bok',
    'diamant',
    'snö',
    'fotboll',
    'lampa',
    'öra',
    'piano',
    'fågel',
    'skata',
    'banan',
];

export default Words;