import React from 'react';

export const paths = [
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.538 1564.35C-4.46585 1537.48 4.00733 1491.61 6.23216 1463.13C12.7804 1379.42 38.0702 1295.59 53.2259 1213.19C60.5355 1173.46 55.4349 1132.32 58.6815 1092.2C71.3559 935.674 98.1605 711.337 235.8 608.379C276.119 578.242 330.154 564.475 378.529 554.692C414.931 547.315 451.467 532.995 489.06 536.349C570.322 543.607 631.99 668.063 649.686 734.177C663.473 785.695 695.473 828.575 711.48 879.146C741.18 972.952 737.926 1085.34 731.18 1182.06C728.036 1227.15 727.796 1362.3 704.08 1399.18C701.303 1403.48 717.681 1432.16 720.691 1438.15C721.326 1439.42 725.066 1463.84 720.423 1459.77C713.362 1453.62 717.614 1438.98 717.91 1430.3H718.518C718.597 1432.55 723.295 1458.98 716.009 1457.56C710.458 1456.5 718.257 1445.69 715.737 1440.64C690.273 1389.56 712.608 1347.9 715.295 1287.27C715.973 1271.96 715.958 1256.89 717.603 1241.59C719.76 1221.47 724.289 1201.94 725.67 1181.71C732.226 1085.54 734.742 974.648 706.798 881.119C691.524 829.955 656.72 787.628 643.804 735.637C629.446 677.846 561.071 543.449 489.06 540.964C451.723 539.662 415.968 552.206 379.511 558.952C123.781 606.249 83.123 870.152 64.0778 1092.36C60.6183 1132.71 65.3835 1174.09 58.1922 1214.09C40.7092 1311.37 -16.5407 1466.91 11.3959 1564.03" fill="black"/>
    <path d="M11.538 1564.35C-4.46585 1537.48 4.00733 1491.61 6.23216 1463.13C12.7804 1379.42 38.0702 1295.59 53.2259 1213.19C60.5355 1173.46 55.4349 1132.32 58.6815 1092.2C71.3559 935.674 98.1605 711.337 235.8 608.379C276.119 578.242 330.154 564.475 378.529 554.692C414.931 547.315 451.467 532.995 489.06 536.349C570.322 543.607 631.99 668.063 649.686 734.177C663.473 785.695 695.473 828.575 711.48 879.146C741.18 972.952 737.926 1085.34 731.18 1182.06C728.036 1227.15 727.796 1362.3 704.08 1399.18C701.303 1403.48 717.681 1432.16 720.691 1438.15C721.326 1439.42 725.066 1463.84 720.423 1459.77C713.362 1453.62 717.614 1438.98 717.91 1430.3H718.518C718.597 1432.55 723.295 1458.98 716.009 1457.56C710.458 1456.5 718.257 1445.69 715.737 1440.64C690.273 1389.56 712.608 1347.9 715.295 1287.27C715.973 1271.96 715.958 1256.89 717.603 1241.59C719.76 1221.47 724.289 1201.94 725.67 1181.71C732.226 1085.54 734.742 974.648 706.798 881.119C691.524 829.955 656.72 787.628 643.804 735.637C629.446 677.846 561.071 543.449 489.06 540.964C451.723 539.662 415.968 552.206 379.511 558.952C123.781 606.249 83.123 870.152 64.0778 1092.36C60.6183 1132.71 65.3835 1174.09 58.1922 1214.09C40.7092 1311.37 -16.5407 1466.91 11.3959 1564.03" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M368.081 560.927C372.467 501.874 385.469 446.135 389.485 387.595C395.347 302.113 376.878 215.96 378.657 130.28C379.039 111.779 384.704 2.50972 408.428 15.3696L408.112 16.0009C392.404 8.82143 393.599 49.0969 391.56 59.0771C381.935 106.059 384.921 158.485 386.811 206.098C389.572 275.683 400.365 345.702 394.025 415.524C389.655 463.649 374.858 510.986 369.047 559.507" fill="black"/>
    <path d="M368.081 560.927C372.467 501.874 385.469 446.135 389.485 387.595C395.347 302.113 376.878 215.96 378.657 130.28C379.039 111.779 384.704 2.50972 408.428 15.3696L408.112 16.0009C392.404 8.82143 393.599 49.0969 391.56 59.0771C381.935 106.059 384.921 158.485 386.811 206.098C389.572 275.683 400.365 345.702 394.025 415.524C389.655 463.649 374.858 510.986 369.047 559.507" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M384.403 16.2695C415.55 6.44702 449.818 24.435 483.096 21.5158C526.074 17.7683 570.259 -0.456101 613.557 3.17308C650.969 6.28944 710.743 32.9559 747.256 16.3484L747.579 17.2952C711.698 36.8217 650.598 8.61671 613.557 5.50035C570.267 1.91062 526.137 20.0563 483.096 23.4882C448.801 26.2101 417.629 10.1943 384.403 15.0859" fill="black"/>
    <path d="M384.403 16.2695C415.55 6.44702 449.818 24.435 483.096 21.5158C526.074 17.7683 570.259 -0.456101 613.557 3.17308C650.969 6.28944 710.743 32.9559 747.256 16.3484L747.579 17.2952C711.698 36.8217 650.598 8.61671 613.557 5.50035C570.267 1.91062 526.137 20.0563 483.096 23.4882C448.801 26.2101 417.629 10.1943 384.403 15.0859" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M399.63 139.334C415.709 121.544 493.334 26.0812 500.679 33.8129L500.513 33.8524C495.211 28.8031 417.192 123.398 399.63 139.334Z" fill="black" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M403.686 138.569C376.928 165.512 360.554 171.626 359.528 204.012H359.355C358.341 171.547 378.226 163.145 403.686 137.859" fill="black"/>
    <path d="M403.686 138.569C376.928 165.512 360.554 171.626 359.528 204.012H359.355C358.341 171.547 378.226 163.145 403.686 137.859" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M740.008 14.8593C727.444 0.30315 718.994 62.3539 715.251 73.912C705.554 103.813 672.004 172.728 690.367 204.01C693.318 209.019 688.88 190.795 693.05 186.732L694.229 187.244C693.089 188.428 687.223 202.984 687.835 204.01C691.492 210.242 686.584 189.572 686.561 182.353C686.533 173.083 687.736 164.207 688.754 154.977C689.843 145.154 724.904 -6.60025 740.292 14.2675" fill="black"/>
    <path d="M740.008 14.8593C727.444 0.30315 718.994 62.3539 715.251 73.912C705.554 103.813 672.004 172.728 690.367 204.01C693.318 209.019 688.88 190.795 693.05 186.732L694.229 187.244C693.089 188.428 687.223 202.984 687.835 204.01C691.492 210.242 686.584 189.572 686.561 182.353C686.533 173.083 687.736 164.207 688.754 154.977C689.843 145.154 724.904 -6.60025 740.292 14.2675" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M682.384 200.286C686.925 197.919 700.554 195.513 696.002 193.185C651.651 170.464 476.67 286.636 549.829 350.659C582.906 379.574 713.145 354.209 738.609 320.876C747.705 309.003 744.676 289.989 749.307 276.104C752.613 266.202 764.352 258.589 768.806 249.201C779.843 225.966 723.812 224.822 716.084 219.694C709.106 215.118 701.193 199.734 691.035 200.68C688.668 200.917 627.004 218.116 643.951 204.901L644.412 205.375C637.375 214.724 685.477 196.775 692.621 195.947C703.607 194.685 710.834 211.016 717.485 215.513C728.203 222.731 747.354 212.87 756.995 220.522C759.441 222.455 772.218 245.414 771.997 248.254C771.247 257.84 758.826 267.031 753.461 274.842C744.049 288.49 751.713 309.397 740.336 323.046C711.323 357.918 576.212 385.294 543.403 347.74C502.327 300.758 562.437 236.577 606.373 215.355C617.703 209.911 640.452 206.124 649.083 199.813C656.211 194.606 690.96 189.635 696.731 194.408C700.609 197.604 687.658 198.748 683.118 200.917" fill="black"/>
    <path d="M682.384 200.286C686.925 197.919 700.554 195.513 696.002 193.185C651.651 170.464 476.67 286.636 549.829 350.659C582.906 379.574 713.145 354.209 738.609 320.876C747.705 309.003 744.676 289.989 749.307 276.104C752.613 266.202 764.352 258.589 768.806 249.201C779.843 225.966 723.812 224.822 716.084 219.694C709.106 215.118 701.193 199.734 691.035 200.68C688.668 200.917 627.004 218.116 643.951 204.901L644.412 205.375C637.375 214.724 685.477 196.775 692.621 195.947C703.607 194.685 710.834 211.016 717.485 215.513C728.203 222.731 747.354 212.87 756.995 220.522C759.441 222.455 772.218 245.414 771.997 248.254C771.247 257.84 758.826 267.031 753.461 274.842C744.049 288.49 751.713 309.397 740.336 323.046C711.323 357.918 576.212 385.294 543.403 347.74C502.327 300.758 562.437 236.577 606.373 215.355C617.703 209.911 640.452 206.124 649.083 199.813C656.211 194.606 690.96 189.635 696.731 194.408C700.609 197.604 687.658 198.748 683.118 200.917" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M590.213 282.931C587.684 279.815 607.021 263.918 614.078 270.624C616.276 272.754 591.688 292.32 589.226 287.073C582.106 271.886 631.486 266.087 601.783 279.105C598.063 280.762 599.648 264.944 590.299 282.774C588.461 286.245 597.85 280.328 601.416 278.632C629.293 265.299 584.966 274.253 589.017 286.561C590.394 290.742 597.38 283.76 601.451 282.064" fill="black"/>
    <path d="M590.213 282.931C587.684 279.815 607.021 263.918 614.078 270.624C616.276 272.754 591.688 292.32 589.226 287.073C582.106 271.886 631.486 266.087 601.783 279.105C598.063 280.762 599.648 264.944 590.299 282.774C588.461 286.245 597.85 280.328 601.416 278.632C629.293 265.299 584.966 274.253 589.017 286.561C590.394 290.742 597.38 283.76 601.451 282.064" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M718.576 264.577C723.101 263.637 727.627 262.732 732.152 261.793L732.176 261.893C727.697 262.9 723.219 263.906 718.74 264.912" fill="black"/>
    <path d="M718.576 264.577C723.101 263.637 727.627 262.732 732.152 261.793L732.176 261.893C727.697 262.9 723.219 263.906 718.74 264.912" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M731.31 272.097C749.109 273.556 708.171 285.312 720.581 275.765C728.249 269.888 756.241 269.335 747.555 265.075C742.423 262.59 725.275 274.03 720.604 275.726C720.549 275.726 713.461 277.777 713.82 277.974C718.277 280.578 733.204 271.19 738.592 268.507C742.237 266.653 753.752 262.511 749.949 263.971C737.859 268.626 723.95 280.223 711.437 280.262C708.115 280.302 716.309 275.647 719.267 274.109C730.072 268.625 740.888 272.215 744.336 267.363C747.002 263.576 728.21 274.266 731.267 272.057C752.576 256.87 730.083 268.744 718.419 274.503C715.65 275.884 714.971 279.75 711.437 279.75C708.723 279.789 716.833 278.724 719.259 277.501C720.107 277.106 744.056 262.156 750.064 264.326C753.894 265.667 742.375 267.048 738.73 268.862C734.076 271.19 715.851 280.972 713.587 277.501C709.571 271.387 727.216 272.057 734.123 269.572C750.509 263.694 748.142 268.152 720.443 275.174" fill="black"/>
    <path d="M731.31 272.097C749.109 273.556 708.171 285.312 720.581 275.765C728.249 269.888 756.241 269.335 747.555 265.075C742.423 262.59 725.275 274.03 720.604 275.726C720.549 275.726 713.461 277.777 713.82 277.974C718.277 280.578 733.204 271.19 738.592 268.507C742.237 266.653 753.752 262.511 749.949 263.971C737.859 268.626 723.95 280.223 711.437 280.262C708.115 280.302 716.309 275.647 719.267 274.109C730.072 268.625 740.888 272.215 744.336 267.363C747.002 263.576 728.21 274.266 731.267 272.057C752.576 256.87 730.083 268.744 718.419 274.503C715.65 275.884 714.971 279.75 711.437 279.75C708.723 279.789 716.833 278.724 719.259 277.501C720.107 277.106 744.056 262.156 750.064 264.326C753.894 265.667 742.375 267.048 738.73 268.862C734.076 271.19 715.851 280.972 713.587 277.501C709.571 271.387 727.216 272.057 734.123 269.572C750.509 263.694 748.142 268.152 720.443 275.174" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M671.141 300.49C649.276 335.4 653.311 296.702 677.299 295.716C682.936 295.519 671.339 306.17 666.202 308.497C663.343 309.799 671.145 300.608 671.189 300.371C672.471 292.679 687.555 298.399 665.915 308.221C661.552 310.193 672.621 301.397 676.084 298.083C676.68 297.492 678.329 296.071 677.508 296.032C667.351 295.559 646.7 307.471 653.58 314.966" fill="black"/>
    <path d="M671.141 300.49C649.276 335.4 653.311 296.702 677.299 295.716C682.936 295.519 671.339 306.17 666.202 308.497C663.343 309.799 671.145 300.608 671.189 300.371C672.471 292.679 687.555 298.399 665.915 308.221C661.552 310.193 672.621 301.397 676.084 298.083C676.68 297.492 678.329 296.071 677.508 296.032C667.351 295.559 646.7 307.471 653.58 314.966" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M620.296 340.605C684.954 289.679 682.488 364.037 731.482 344.432L731.648 344.905C697.983 360.29 693.976 332.598 672.575 321.434C657.132 313.426 629.688 333.978 620.485 339.659" fill="black"/>
    <path d="M620.296 340.605C684.954 289.679 682.488 364.037 731.482 344.432L731.648 344.905C697.983 360.29 693.976 332.598 672.575 321.434C657.132 313.426 629.688 333.978 620.485 339.659" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M646.565 375.292C650.967 400.735 644.948 421.721 645.129 446.77C645.188 455.409 644.147 484.009 644.829 478.762L646.028 480.577C641.78 482.273 643.342 393.911 646.565 375.292Z" fill="black" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M633.572 529.765C636.179 507.004 641.647 500.534 653.99 486.333C660.171 479.193 641.153 521.757 643.958 512.763C646.648 504.124 654.282 492.724 659.477 484.124C660.175 482.98 663.757 473.671 664.049 473.868C686.364 488.424 646.388 498.049 646.155 505.82C646.14 506.294 672.841 474.538 656.258 478.68C648.171 480.732 639.54 492.093 638.234 500.14C637.445 505.031 640.996 514.854 636.045 514.854C635.402 514.854 636.72 506.096 636.783 505.741C637.615 500.731 642.96 473.552 628.956 478.759C625.272 480.14 630.806 503.098 625.781 508.187C623.517 510.475 622.443 518.364 620.159 516.076C615.027 510.948 638.83 472.093 643.761 467.162L643.8 467.202C634.108 476.945 631.615 489.607 625.785 501.284C623.438 506.017 623.473 519.588 619.753 515.879C618.452 514.578 628.428 505.781 628.818 504.637C630.183 500.574 623.493 475.604 631.406 478.326C640.901 481.6 641.11 514.854 635.635 514.854C632.854 514.854 648.108 465.584 660.928 478.483C666.613 484.203 654.629 495.88 651.788 500.14C651.702 500.258 645.974 506.846 645.946 505.702C645.481 488.66 680.084 500.574 664.352 474.144C661.417 469.213 660.147 484.834 657.591 490.002C653.776 497.655 648.597 505.741 643.603 512.408C643.327 512.763 646.282 508.424 646.964 505.702C648.889 498.049 649.532 491.777 653.99 486.649C657.808 482.27 644.27 493.828 641.666 499.035C636.365 509.647 635.292 516.708 633.572 529.449" fill="black"/>
    <path d="M633.572 529.765C636.179 507.004 641.647 500.534 653.99 486.333C660.171 479.193 641.153 521.757 643.958 512.763C646.648 504.124 654.282 492.724 659.477 484.124C660.175 482.98 663.757 473.671 664.049 473.868C686.364 488.424 646.388 498.049 646.155 505.82C646.14 506.294 672.841 474.538 656.258 478.68C648.171 480.732 639.54 492.093 638.234 500.14C637.445 505.031 640.996 514.854 636.045 514.854C635.402 514.854 636.72 506.096 636.783 505.741C637.615 500.731 642.96 473.552 628.956 478.759C625.272 480.14 630.806 503.098 625.781 508.187C623.517 510.475 622.443 518.364 620.159 516.076C615.027 510.948 638.83 472.093 643.761 467.162L643.8 467.202C634.108 476.945 631.615 489.607 625.785 501.284C623.438 506.017 623.473 519.588 619.753 515.879C618.452 514.578 628.428 505.781 628.818 504.637C630.183 500.574 623.493 475.604 631.406 478.326C640.901 481.6 641.11 514.854 635.635 514.854C632.854 514.854 648.108 465.584 660.928 478.483C666.613 484.203 654.629 495.88 651.788 500.14C651.702 500.258 645.974 506.846 645.946 505.702C645.481 488.66 680.084 500.574 664.352 474.144C661.417 469.213 660.147 484.834 657.591 490.002C653.776 497.655 648.597 505.741 643.603 512.408C643.327 512.763 646.282 508.424 646.964 505.702C648.889 498.049 649.532 491.777 653.99 486.649C657.808 482.27 644.27 493.828 641.666 499.035C636.365 509.647 635.292 516.708 633.572 529.449" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>        
    <path fillRule="evenodd" clipRule="evenodd" d="M648.021 423.476C615.915 429.156 568.014 430.616 569.162 388.92H570.025C571.11 428.407 616.857 426.829 647.717 422.411" fill="black"/>
    <path d="M648.021 423.476C615.915 429.156 568.014 430.616 569.162 388.92H570.025C571.11 428.407 616.857 426.829 647.717 422.411" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M638.001 433.582C659.24 424.588 695.646 417.369 716.071 405.258C719.914 402.97 721.977 394.016 726.107 394.252L726.454 394.923C723.133 395.239 720.572 404.667 716.071 406.599C692.34 416.816 662.052 424.114 637.591 432.753" fill="black"/>
    <path d="M638.001 433.582C659.24 424.588 695.646 417.369 716.071 405.258C719.914 402.97 721.977 394.016 726.107 394.252L726.454 394.923C723.133 395.239 720.572 404.667 716.071 406.599C692.34 416.816 662.052 424.114 637.591 432.753" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M631.318 489.019C626.312 495.528 620.194 503.891 624.911 512.609C627.428 517.263 635.353 511.583 636.458 514.187C647.763 540.656 662.233 634.067 690.398 645.073L690.082 645.547C663.566 638.052 640.249 538.762 636.095 514.345C635.409 510.281 625.697 516.238 623.973 512.53C619.476 502.905 625.223 495.133 631.18 488.467" fill="black"/>
    <path d="M631.318 489.019C626.312 495.528 620.194 503.891 624.911 512.609C627.428 517.263 635.353 511.583 636.458 514.187C647.763 540.656 662.233 634.067 690.398 645.073L690.082 645.547C663.566 638.052 640.249 538.762 636.095 514.345C635.409 510.281 625.697 516.238 623.973 512.53C619.476 502.905 625.223 495.133 631.18 488.467" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>,
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M652.941 487.546C650.188 518.275 585.889 548.452 584.855 593.107H584.626C583.525 545.494 646.586 525.376 652.669 487.664" fill="black"/>
    <path d="M652.941 487.546C650.188 518.275 585.889 548.452 584.855 593.107H584.626C583.525 545.494 646.586 525.376 652.669 487.664" stroke="black" strokeWidth="4" strokeLinejoin="round"/>
  </>
];

function Man(props) {
  return (
    <>
    <svg className="hangmanFigure" viewBox="0 0 774 1566" fill="none" xmlns="http://www.w3.org/2000/svg">
      {
        paths.filter((elm, index) => props.numberOfWrongAnswers > index)
      }      
    </svg>
    </>
  );
}

export default Man;
