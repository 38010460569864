import React from 'react';
import Chars from '../helpers/chars';

function CharBoard(props) {
  return (
    <ul className="char-list">
      {props.availableChars.map(char => {
      return <li key={`index-${char}`}>
                <button                     
                    onClick={() => {
                        props.takeChar(char);
                    }}>
                      {Chars(true)[char]}
                </button>
            </li>
      })}
    </ul>
  );
}

export default CharBoard;
